import popsss from 'lib/popsss';
import $ from 'jquery';
import { INBOX_CACHE_NAME } from './constants';

interface InboxResult {
  unread: boolean;
  unresponded: boolean;
}

interface Options {
  clearCache?: boolean;
}

function updateBadges() {
  return Promise.resolve(
    popsss
      .getJSON('/api/account/v1/inbox_counts', undefined, {
        callerHandlesErrors: true,
      })
      .then((result: InboxResult) => {
        if (result.unread) {
          $('#navbar-inbox > .badge-unread').show();
        } else {
          $('#navbar-inbox > .badge-unread').hide();
        }
        if (result.unresponded) {
          $('#navbar-inbox > .badge-unresponded').show();
        } else {
          $('#navbar-inbox > .badge-unresponded').hide();
        }
      })
      .catch(() => {
        return caches.delete(INBOX_CACHE_NAME);
      }),
  );
}

export async function clearInboxCache() {
  return await caches.delete(INBOX_CACHE_NAME);
}

export async function refreshInboxBadges(
  { clearCache }: Options = { clearCache: false },
) {
  if (clearCache) {
    try {
      await clearInboxCache();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(`Unable to delete inbox cache: ${e}`);
    }
  }

  return updateBadges();
}
