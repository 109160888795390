import debounce from 'lib/debounce';

const HEADER_NOT_SCROLLED_CLASS = 'header---not-scrolled';
const HEADER_SCROLLED_CLASS = 'header---scrolled';

window.__prevScrollPosition = window.pageYOffset;

/*
  This function adjusts the scroll-padding-top of the scrolling element to the navigation bar size.
*/
function updateHeaderNavigationHeight() {
  const headerElement = document.getElementById('header-navigation');

  if (headerElement) {
    const headerElementClientRec = headerElement.getBoundingClientRect();
    const currentNavigationHeight = headerElementClientRec.bottom;

    document.scrollingElement.style.scrollPaddingTop =
      currentNavigationHeight + 'px';
  }
}

function hideMainNavigation() {
  /*
    The idea of this is to hide the main navigation bar as we scroll, to only show the projects bar.
    This should only be applying when the projects bar is showing.
  */
  const currentScrollPosition = window.pageYOffset;
  const classList = document.getElementById('header-navigation').classList;

  if (window.__prevScrollPosition > currentScrollPosition) {
    if (!classList.contains(HEADER_NOT_SCROLLED_CLASS)) {
      classList.add(HEADER_NOT_SCROLLED_CLASS);
      classList.remove(HEADER_SCROLLED_CLASS);
    }
  } else {
    if (!classList.contains(HEADER_SCROLLED_CLASS)) {
      classList.remove(HEADER_NOT_SCROLLED_CLASS);

      classList.add(HEADER_SCROLLED_CLASS);
    }
  }

  window.__prevScrollPosition = currentScrollPosition;
}

// Only want this behaviour when we show the activity navigation bar below the main one
if (document.getElementById('navbar--activity-navigation')) {
  document.addEventListener('DOMContentLoaded', () =>
    requestAnimationFrame(hideMainNavigation),
  );
  // Debounce to at most 20 calls/second (50 ms)
  document.addEventListener(
    'scroll',
    debounce(() => requestAnimationFrame(hideMainNavigation), 50),
  );
}

document.addEventListener('DOMContentLoaded', () =>
  requestAnimationFrame(updateHeaderNavigationHeight),
);
