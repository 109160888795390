import $ from 'jquery';
import events from 'lib/events';
import classNames from 'classnames';

import { cancelProgress, PROGRESS_DID_CANCEL } from './actions';
import styles from './styles.css';
import { Store, Unsubscribe } from 'redux';
import popsss from 'lib/popsss';
declare global {
  interface Window {
    store: Store;
  }
}

export interface ProgressCallbacks<DeferredResultsParams> {
  start?: (progress: Progress<DeferredResultsParams>) => void;
  dismiss?: (progress: Progress<DeferredResultsParams>) => void;
  addMessage?: (
    progress: Progress<DeferredResultsParams>,
    message: string,
  ) => void;
  cancel?: (progress: Progress<DeferredResultsParams>) => void;
}

export interface ProgressData {
  id: string;
  use_progress?: boolean;
  title?: string;
  revocable?: boolean;
  use_events?: boolean;
}

export interface DeferredResultsParams {
  message?: string;
  progress?: number;
  [key: string]: any;
}

/*
  Wrapper for managing events from Pusher

  By default this will bring up a loading spinner, and block the UI,
  waiting on results from Pusher.
  This can be overriden by passing in callbacks, to hook into and replace some events
*/
export class Progress<DeferredResult extends DeferredResultsParams> {
  data: ProgressData;
  unsubscribeFromStore: Unsubscribe;
  deferred: JQueryDeferred<any>;
  callbacks: ProgressCallbacks<DeferredResult>;
  cancelled: boolean;
  $overlay: JQuery;
  $events: JQuery;
  $message: JQuery;
  $progressbar: JQuery;
  eventsIndex: number;
  completionPollTimeoutID: NodeJS.Timeout;

  constructor(
    data: ProgressData,
    deferred: JQueryDeferred<DeferredResult>,
    callbacks: ProgressCallbacks<DeferredResult> = {},
  ) {
    this.data = data;
    this.deferred = deferred;
    this.callbacks = callbacks;
    this.cancelled = false;
    this.completionPollTimeoutID = null;

    if (window.store) {
      this.unsubscribeFromStore = window.store.subscribe(() => {
        const state = global.window.store.getState().progress;
        if (state.cancelled) {
          this.cancel();
        }
      });
    }
  }

  pollCompletionEndpoint = async () => {
    const progress = this;
    // Run the poll every 5 seconds, until complete
    // This is a safety in the case where pusher fails/is unavailable.
    // The task may still complete, without the confirmation arriving from pusher

    this.completionPollTimeoutID = setTimeout(() => {
      popsss
        .postFormData('/api/events/v1/task_completion', {
          id: progress.data.id,
        })
        .done((response: { completed?: boolean; data: unknown }) => {
          if (response.completed) {
            progress.dismiss();
            progress.deferred.resolve(response.data);
          } else {
            progress.pollCompletionEndpoint();
          }
        });
    }, 5000);
  };

  start = () => {
    const progress = this;
    if (progress.callbacks.start) {
      progress.callbacks.start(progress);
    } else {
      progress.createOverlay();
    }

    events.subscribe(`progress_${progress.data.id}`).then(
      (channel) => {
        // Poll task completion endpoint
        // If the task has finished resolve ProgressFactory
        progress.pollCompletionEndpoint();

        // Done event
        channel.bind('done', (data: DeferredResult) => {
          progress.dismiss();
          progress.deferred.resolve(data);
        });
        // Fail event
        channel.bind('fail', (data: DeferredResult) => {
          progress.dismiss();
          progress.deferred.reject(data);
        });
        // Message event
        channel.bind('event', (data: DeferredResult) => {
          if (data.message) {
            progress.addMessage(data.message);
          }
          if (data.progress && progress.data.use_progress) {
            progress.$progressbar
              .children('.progress-bar')
              .css('width', `${data.progress}%`);
          }
        });
      },
      () => {
        popsss.flashWarning(
          'Could not subscribe to progress events. Your request will ' +
            "still be processed but you won't see any progress information.",
        );
      },
    );
  };

  dismiss = () => {
    const progress = this;
    if (progress.callbacks.dismiss) {
      progress.callbacks.dismiss(progress);
    } else {
      progress.removeOverlay();
    }

    if (window.store) {
      progress.unsubscribeFromStore();
    }
    if (progress.completionPollTimeoutID) {
      clearTimeout(this.completionPollTimeoutID);
    }
  };

  removeOverlay = () => {
    $('body')
      .removeClass('modal-open')
      .children('.modal-backdrop,.progress-modal')
      .remove();
  };

  createOverlay = () => {
    const progress = this;
    $('.modal-backdrop').remove();

    // TODO: Refactor this into a React component
    progress.$overlay = $('<div />', {
      class: classNames('progress-modal-content', styles.progressModalContent),
    });

    $('body')
      .addClass('modal-open')
      .append(
        $('<div />', {
          class: classNames(
            'modal-backdrop',
            'progress-backdrop',
            styles.progressBackdrop,
          ),
        }),
      )
      .append(
        $('<div />', {
          class: classNames('progress-modal', styles.progressModal),
        }).append(progress.$overlay),
      )
      .children('.modal')
      .remove();

    progress.eventsIndex = 0;
    progress.$events = $('<div />', {
      class: classNames('progress-events', styles.progressEvents),
    });
    progress.$message = $('<p />', { class: 'lead' }).appendTo(
      progress.$events,
    );
    if (progress.data.title) {
      progress.$message.text(progress.data.title);
    }
    progress.$overlay.append(
      $('<div />', {
        class: classNames(
          'progress-events-container',
          styles.progressEventsContainer,
        ),
      }).append(progress.$events),
    );

    if (progress.data.use_progress) {
      progress.$progressbar = $('<div />', {
        class: 'progress progress-striped active',
      }).append($('<div />', { class: 'progress-bar' }).css('width', 0));
      progress.$overlay.append(progress.$progressbar);
    } else {
      progress.$overlay.append(
        $('<div />', { class: 'spinner spinner-white spinner-lg' }),
      );
    }

    if (progress.data.revocable) {
      $('<div />')
        .append(
          $('<button />', { type: 'button', class: 'btn btn-sm' })
            .text('Cancel')
            .on('click', () => {
              if (window.store) {
                window.store.dispatch(cancelProgress());
              } else {
                progress.cancel();
              }
            }),
        )
        .appendTo(progress.$overlay);
    }
  };

  addMessage = (message: string) => {
    const progress = this;
    if (progress.callbacks.addMessage) {
      progress.callbacks.addMessage(progress, message);
      return;
    }

    if (progress.data.use_events) {
      if (progress.$events && progress.$events.children('p').length > 5) {
        progress.$events.children('p:first').remove();
      }
      progress.$message.before($('<p/>').text(progress.$message.text()));
    }
    progress.$message.text(message);
  };

  cancel = () => {
    const progress = this;
    if (progress.callbacks.cancel) {
      progress.callbacks.cancel(progress);
      return;
    }

    progress.dismiss();

    progress.cancelled = true;

    if (window.store) {
      window.store.dispatch({ type: PROGRESS_DID_CANCEL });
    }

    popsss.postFormData('/api/events/v1/cancel_progress', {
      id: progress.data.id,
    });
    progress.deferred.reject();
  };
}

// eslint-disable-next-line import/no-default-export -- FIXME: Convert to a named export https://github.com/wegotpop/popsss/wiki/Imports#default-exports
export default { Progress };
