import React from 'react';
import { createRoot } from 'react-dom/client';
import classNames from 'classnames';
import styles from './GDPRBanner.css';

import { hasLocalStorage } from 'lib/local-storage-check';
import { DEFAULT_SITE_NAME } from 'lib/constants';

const GDPR_WARNING_DISMISS = 'gdpr-warning-dismiss';

function shouldShowGDPRWarning() {
  return hasLocalStorage() && !localStorage.getItem(GDPR_WARNING_DISMISS);
}

function GDPRBanner() {
  function dismiss() {
    $('#browser-warning-gdpr').fadeOut();
    if (hasLocalStorage()) {
      localStorage.setItem(GDPR_WARNING_DISMISS, 'dismissed');
    }
  }

  return (
    <div
      id="browser-warning-gdpr"
      className={classNames(
        'alert alert-warning gdpr-warning z4',
        styles.banner,
      )}
    >
      <span className={styles.text}>
        We have updated our{' '}
        <a href="https://www.wegotpop.com/pages/policies/privacy/">
          Privacy Policy
        </a>{' '}
        to reflect the new Data Protection Regulation. Please read and take some
        time to understand it as all usage of {DEFAULT_SITE_NAME} is now subject
        to the updated policy.
      </span>
      <button
        className={classNames('close', styles.close, 'p1')}
        id="gdpr-browser-warning-close-button"
        onClick={dismiss}
      >
        x
      </button>
    </div>
  );
}

export function renderGDPRWarningBanner() {
  $(document).ready(() => {
    if (shouldShowGDPRWarning()) {
      const GDPRContainer = document.getElementById('gdpr-warning');
      if (GDPRContainer) {
        const root = createRoot(GDPRContainer);
        root.render(<GDPRBanner />);
      }
    }
  });
}
