import 'app/public-path'; // Must be the first import
// Currently no webpack compatible version from NPM https://modernizr.com/docs
import 'deps/modernizr'; /* global Modernizr */
import $ from 'jquery';
import 'bootstrap';
import popsss from 'lib/popsss';

import events from 'lib/events';
import { renderWarningBanner } from 'components/WarningBanner';
import { renderGDPRWarningBanner } from 'lib/warning_banner/GDPRBanner';

import 'react-datepicker-v1/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import 'css/react-datepicker.css';

import './navigationScroller';
import { refreshInboxBadges } from '../lib/message/inboxBadgeHelper';
import { setupLocaleAndTimezone } from './setup-locale-and-timezone';
import { setupIdleJS } from './idle';
import './renderingChecker';
// Check if browser supports ES6 Collections
warningBanner();
// GDPR warning banner
renderGDPRWarningBanner();

const dataConfig = window.__CONFIG__;
const roots = dataConfig.roots;

const pusherConfig = dataConfig.pusher;
const $attachments = $('#download-attachments');
if ($attachments.length) {
  pusherConfig.channel = $attachments.data('pusher-channel');
}

window.app_root = roots.app_root;
window.controller_root = roots.controller_root;
window.static_root = roots.static_root;
window.locale = dataConfig.locale;

if (dataConfig.user_id) {
  window.popsss_user_id = dataConfig.user_id;
}

// Add default document setup/listeners
window.popsss = popsss;
window.$ = $;

const $document = $(document);
$document.ready(() => {
  // Activate tooltips and help text popovers
  $('.has-tooltip').tooltip();

  const body = $('body');
  // Flash message
  if (body.data('flash')) {
    popsss.flash(body.data('flash'), body.data('flash-status'));
  }
});

setupLocaleAndTimezone(
  window.__CONFIG__.locale,
  window.__CONFIG__.timezone,
  !window.__CONFIG__?.show_12_hour_clock,
);

// Configure raven
if (dataConfig.sentry) {
  const config = dataConfig.sentry;

  if (config.username && window.Sentry) {
    try {
      window.Sentry.onLoad(function loadSentry() {
        window.Sentry.init({
          release: config.release,
          environment: config.environment,
        });
        window.Sentry.setUser &&
          window.Sentry.setUser({
            name: config.username,
            id: dataConfig.user_id,
          });
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }
}

// Configure pusher
if (pusherConfig.channel) {
  events.configurePusher(pusherConfig);
}

function isIOSSafariOrChrome() {
  const ua = window.navigator.userAgent;
  return ua.match(/(iPod|iPhone|iPad)/) && ua.match(/AppleWebKit/);
}

function isChrome() {
  return !!window.chrome;
}

function isBrowserSupported() {
  return isChrome() || isIOSSafariOrChrome();
}

// Check if browser is supported
function warningBanner() {
  $(document).ready(() => {
    try {
      // Note if you want to add a new check, you may have to regenerate a Modernizr module.
      // Check deps/modernizr.js for the last generated file (including the URL)
      const browser_checks = {
        es6collections: Modernizr.es6collections,
        querySelector: Modernizr.queryselector,
        customElements: Modernizr.customelements,
        'chrome/iOSSafari': isBrowserSupported(),
      };
      for (const check_name in browser_checks) {
        const check = browser_checks[check_name];

        if (!check) {
          renderWarningBanner();
        }
      }
    } catch (e) {
      throw new Error(`${e}\nError carrying out browser checks`);
    }
  });
}

// Avoid refreshing the inbox badge count on these pages as they are usually not authenticated
// non-authenticated requests to this endpoint will trigger an unauthorized error
if (
  ![
    '/login',
    '/logout_page',
    '/post_logout',
    '/faq',
    '/apply',
    '/register',
    '/email_login',
    '/verify_otp',
  ].includes(window.location.pathname)
) {
  $(document).ready(refreshInboxBadges);
}

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/sw.js').catch((registrationError) => {
      // eslint-disable-next-line no-console
      console.warn(`Could not register service worker\n${registrationError}`);
    });
  });
}

if (__DEV__) {
  // eslint-disable-next-line no-console
  console.group(
    '%c  [POPSSS] Code locations',
    'font-weight: bold; font-size: 1.2em',
  );
  // eslint-disable-next-line no-console
  console.log(`Controller root: ${dataConfig.roots.controller_root}`);

  if (dataConfig.js_page) {
    // eslint-disable-next-line no-console
    console.log(`Loaded page from popsss/js/${dataConfig.js_page}`);
  } else if (dataConfig.js_view) {
    // eslint-disable-next-line no-console
    console.log(
      `Rendering view '${dataConfig.js_view}' from popsss/js/views/${dataConfig.js_entrypoint}`,
    );
  } else {
    const jsCodeLocation = dataConfig.js_entrypoint
      ? `JS code can be found in: popsss/js/views/${dataConfig.js_entrypoint}`
      : 'There is no JS code for this page';

    // eslint-disable-next-line no-console
    console.log(`This page is using a Mako template. ${jsCodeLocation}`);
  }

  // eslint-disable-next-line no-console
  console.groupEnd();
  setupIdleJS();
} else {
  setupIdleJS();
}
