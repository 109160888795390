import IdleJs from 'idle-js';

export function setupIdleJS() {
  /*
    Desired Idle timeout behaviour: if the user is active in multiple tabs, they are only logged out if they are inactive in all the tabs.

    Example with 2 tabs:
     - active in 2 tabs -> kept logged in
     - active in 1 tab, inactive in 1 tab -> when idle temout period passes in the inactive tab they are not logged out
     - inactive in 2 tabs -> the user is logged out when the Idle timout expires

  Notes:
   - localStorage setItem doesn't trigger an update if the updated key exists in localStorage but its value is equal to the 'new value'
   - onIdle: is called in each tab automatically after the inactivity timeout expired for that tab.
   Example:
   - Tab 1: last active: 12:00:10
   - Tab 2 : last active: 12:00:80
   - When the activity happens in Tab 2, lastActive is updated in Tab 1. However, that update is outside Idle JS, thus onIdle is still called at 12:01:10, but it detects
    the updated "lastActive" value the user is stayed logged in.
  */
  function logout() {
    window.localStorage.setItem('logout', true);
    window.localStorage.removeItem('lastActive');
    popsss
      .post('/logout_handler')
      .done(() => popsss.follow('/login?was_idle=true'));
  }

  const IDLE_TIMEOUT =
    1000 * 60 * window.__CONFIG__.site.inactive_timeout_minutes;

  if (IDLE_TIMEOUT === 0) {
    return;
  }

  const idle = new IdleJs({
    idle: IDLE_TIMEOUT,
    onIdle: () => {
      if (
        lastActiveDate() &&
        new Date(Date.now() - IDLE_TIMEOUT).toISOString() > lastActiveDate()
      ) {
        logout();
      }
    },
  });

  function lastActiveDate() {
    return (
      window.localStorage.lastActive ?? new Date(window.localStorage.lastActive)
    );
  }
  //Only track if the user is logged in
  if (window.__CONFIG__.user_id) {
    window.addEventListener('storage', (event) => {
      if (event.storageArea != localStorage) {
        return;
      }
      if (event.key == 'lastActive') {
        if (window.localStorage.logout && event.newValue === null) {
          logout();
        } else {
          window.localStorage.setItem('lastActive', event.newValue);
        }
      }
    });

    if (
      lastActiveDate() &&
      new Date(Date.now() - IDLE_TIMEOUT).toISOString() > lastActiveDate()
    ) {
      logout();
    } else {
      window.localStorage.setItem('lastActive', new Date().toISOString());
      // Update the last active anytime the user does something
      ['mousemove', 'scroll', 'keydown', 'mousedown', 'touchstart'].forEach(
        (event) => {
          window.addEventListener(event, () => {
            // console.log("event detected", lastActiveDate(), new Date(), lastActiveDate() > new Date(), lastActiveDate() < new Date() );
            if (
              lastActiveDate() &&
              lastActiveDate() < new Date().toISOString()
            ) {
              window.localStorage.setItem(
                'lastActive',
                new Date().toISOString(),
              );
            }
          });
        },
      );

      idle.start();
    }
  }
}
