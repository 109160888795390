import moment from 'moment-timezone';
import usLocale from 'date-fns/locale/en-US';
import gbLocale from 'date-fns/locale/en-GB';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { Locales } from '../lib/locale';

export function setupLocaleAndTimezone(
  locale: Locales,
  timezone: string,
  show12HourClock: boolean,
) {
  // Configuring moment for timezones/locale to match the ones used in the backend
  if (locale === 'en_GB') {
    require('moment/locale/en-gb');
    registerLocale('gb', gbLocale);
    setDefaultLocale('gb');

    moment.updateLocale('en-gb', {
      calendar: {
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        nextWeek: 'ddd D MMM YYYY',
        lastDay: '[Yesterday]',
        lastWeek: 'ddd D MMM YYYY',
        sameElse: 'ddd D MMM YYYY',
      },
    });
    moment.locale('en-gb');
  } else {
    registerLocale('us', usLocale);
    setDefaultLocale('us');

    // en_US
    moment.updateLocale('en', {
      calendar: {
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        nextWeek: 'ddd MMM D, YYYY',
        lastDay: '[Yesterday]',
        lastWeek: 'ddd MMM D, YYYY',
        sameElse: 'ddd MMM D, YYYY',
      },
    });

    if (show12HourClock) {
      moment.updateLocale('en', {
        // Override long date formats to use 24 hour clock
        longDateFormat: {
          L: 'MM/DD/YYYY',
          LL: 'MMMM D, YYYY',
          LT: 'HH:mm',
          LTS: 'HH:mm:ss',
          LLL: 'MMMM Do YYYY LT z',
          LLLL: 'dddd, MMMM Do YYYY LT z',
        },
      });
    }

    moment.locale('en');
  }

  moment.tz.setDefault(timezone);
}
