// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
// eslint-disable-next-line import/no-default-export -- FIXME: Convert to a named export https://github.com/wegotpop/popsss/wiki/Imports#default-exports
export default function debounce(func: any, wait = 1000, immediate = false) {
  let timeout: ReturnType<typeof setTimeout>;
  return function debouncedFunction(...args: any) {
    const context = this;

    const later = () => {
      timeout = null;
      if (!immediate) {
        func.apply(context, args);
      }
    };

    const callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) {
      func.apply(context, args);
    }
  };
}
