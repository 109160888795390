function runAfterFramePaint(callback: () => void) {
  function handler() {
    // Check if there are any spinners running, if not, we're done
    if (
      document.getElementById('content-body') &&
      document.getElementById('content-body').childElementCount &&
      document.getElementsByClassName('spinner').length === 0
    ) {
      const messageChannel = new MessageChannel();

      messageChannel.port1.onmessage = callback;
      messageChannel.port2.postMessage(undefined);
    } else {
      // Wait for the next available frame to check
      requestAnimationFrame(handler);
    }
  }

  requestAnimationFrame(handler);
}

runAfterFramePaint(() => {
  if (__DEV__) {
    // eslint-disable-next-line no-console
    console.debug('Done rendering');
  }

  // @ts-ignore
  window._rendering_done = true;
});

const dataConfig = window.__CONFIG__;

// @ts-ignore
if (!dataConfig.js_page && !dataConfig.js_view) {
  // @ts-ignore
  window._rendering_done = true;
}
