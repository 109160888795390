import $ from 'jquery';
import popsss from '../popsss';
import { ConfirmModalOptions } from '../modal';
import { InputModalOptions } from '../../components/modal/InputModal';
import React from 'react';

export const modal = {
  // Create empty modal
  createEmpty: function (id?: string) {
    const $modal = $('<div />', {
      class: 'modal fade',
      tabindex: -1,
      role: 'dialog',
      'aria-hidden': 'true',
    });

    if (id) {
      $modal.attr('id', id);
    } else {
      $('#modal, body > .modal-backdrop').remove();
      $modal.attr('id', 'modal').attr('aria-labelledby', 'modal_title');
    }

    return $modal.appendTo($('body'));
  },

  // Hide current modal (as long as it is using default a id)
  dismiss: function (id?: string) {
    $(id ? id : '#modal').modal('hide');
  },

  // Load modal from remote
  remote: function (url: string, data: object) {
    const $modal = modal.createEmpty();
    const deferred = $.Deferred();

    $modal.load(popsss.url(url), data, function () {
      deferred.resolve($modal);
      $modal.modal();
    });

    return deferred;
  },

  // Create a modal dialog
  dialog: function (
    title: string,
    msg: JQuery.htmlString | JQuery.Node,
    options?: any,
  ) {
    options = options || {};
    const $modal = modal.createEmpty(options.id);
    const dialog = $('<div />', { class: 'modal-dialog' });
    const content = $('<div />', { class: 'modal-content' }).appendTo(dialog);

    content.append(
      $('<div />', { class: 'modal-header' }).append(
        $('<button />', {
          type: 'button',
          class: 'close',
          'data-dismiss': 'modal',
          'aria-hidden': 'true',
        }).html('&times;'),
        $('<h4 />', { class: 'modal-title' }).text(title),
      ),
    );

    content.append($('<div />', { class: 'modal-body' }).html(msg));
    content.append(
      $('<div />', { class: 'modal-footer' }).append(
        options.buttons
          ? options.buttons
          : $('<button />', {
              type: 'button',
              class: 'btn btn-default',
              'data-dismiss': 'modal',
            }).text('Close'),
      ),
    );

    if (options.disable_fade) {
      $modal.removeClass('fade');
    }

    $modal
      .append(dialog)
      .modal({
        keyboard: options.keyboard || true,
      })
      .on('shown.bs.modal', function () {
        $modal.find('input,textarea,select')[0]?.focus();
      });

    return $modal;
  },

  // Create a confirm dialog
  confirm: function (
    title: string,
    msg: React.ReactNode,
    _options?: ConfirmModalOptions,
  ) {
    return promiseToDeferred(
      import('lib/modal').then(({ showConfirmModal }) =>
        showConfirmModal(title, msg, _options),
      ),
    );
  },

  // Create a notification dialog
  notification: function (title: string, message: string) {
    import('lib/modal').then(({ showNotificationModal }) =>
      showNotificationModal(title, message),
    );
  },

  // Create an input modal
  input: function (title: string, options_?: InputModalOptions) {
    return promiseToDeferred(
      import('lib/modal').then(({ showInputModal }) =>
        showInputModal(title, options_),
      ),
    );
  },
};

$(document).on('hidden.bs.modal', function () {
  // Re add the 'modal-open' class if there is still a modal on display
  if (
    $('.modal').filter(function () {
      const modalData = $(this).data('bs.modal') || { isShown: false };
      return modalData.isShown;
    }).length >= 1
  ) {
    $('body').addClass('modal-open');
  }
});

function promiseToDeferred<T>(promise: Promise<T>): JQueryPromise<T> {
  const deferred: JQueryDeferred<T> = $.Deferred();
  promise
    .then(function (result) {
      deferred.resolve(result);
    })
    .catch(function (error) {
      deferred.reject(error);
    });
  return deferred.promise();
}

// FIXME: Remove uses of quickmodal_* symbols
// Global symbols required for code that has not yet been converted to AMD
// @ts-ignore
window.quickmodal_remote = function (url, callback, data) {
  modal.remote(url, data).then(callback);
};
// @ts-ignore
window.quickmodal_dialog = function (title, msg, buttons, id) {
  return modal.dialog(title, msg, { buttons: buttons, id: id });
};
// @ts-ignore
window.quickmodal_confirm = function (title, msg, options, callback) {
  modal.confirm(title, msg, options).then(callback);
};
