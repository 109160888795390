/**
 * POP Self Service System
 * Copyright 2016 POP Global Limited
 *
 * Authors:
 * Amit Shah <amit@wegotpop.com>
 */

export const PROGRESS_CANCEL = 'PROGRESS_CANCEL';
export const PROGRESS_DID_CANCEL = 'PROGRESS_DID_CANCEL';

export const cancelProgress = () => ({ type: PROGRESS_CANCEL });
