import $ from 'jquery';

import { hasLocalStorage } from 'lib/local-storage-check';

const warning_storage_key = 'browser-warning';
const cur_epoch = Date.now();
const dismiss_timeout_days = 30;
const one_day_ms = 24 * 3600 * 1000;

function shouldShowMessage() {
  if (!hasLocalStorage()) {
    return true;
  }

  if (localStorage.getItem(warning_storage_key)) {
    const warning_storage = JSON.parse(
      String(localStorage.getItem(warning_storage_key)),
    );
    const dismissed_epoch = warning_storage.epoch;
    if (cur_epoch - dismissed_epoch >= one_day_ms * dismiss_timeout_days) {
      localStorage.removeItem(warning_storage_key);
      return true;
    }
    return false;
  }
  return true;
}

function handleClick() {
  $('#browser-warning').fadeOut();
  if (hasLocalStorage()) {
    localStorage.setItem(
      warning_storage_key,
      JSON.stringify({
        dismissed: true,
        epoch: cur_epoch,
      }),
    );
  }
}

export function renderWarningBanner() {
  $(document).ready(() => {
    if (shouldShowMessage()) {
      $('#browser-warning').show();
      $('#browser-warning-close-button').click(handleClick);
    } else {
      $('#browser-warning').hide();
    }
  });
}
